<template>
  <div class="vm-tree-multi-wrapper">
    <div v-loading="loading" class="vm-tree-wrapper">
      <el-input v-model="filterText" :placeholder="$t('common.filter')"></el-input>
      <el-tree
        ref="tree"
        class="vm-tree-tree"
        :data="data"
        :filter-node-method="filterNode"
        :props="props"
        show-checkbox
        node-key="key"
        @check="(checkedNodes, checkedKeys)=>{check(checkedKeys.checkedNodes)}"></el-tree>
    </div>
    <div class="selected">
      <div class="title">已选择{{checkedElevators.length}}台</div>
      <div class="lineContainer">
        <div v-for="(val,index) in checkedData" :key="index" class="line">
          <div :class="[val.type==='useUnit'?'useUnit':'elevator']">{{val.name}}</div>
          <i v-if="val.type!=='useUnit'" class="el-icon-circle-close" @click="deleteLine(val)"></i>
        </div>
      </div>
      <div class="operation">
        <el-button type="danger" @click="deleteAll">全部删除</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: "elevator",
      },
    },
    data() {
      return {
        loading: false,
        props: {
          label: "name",
        },
        data: [],
        checkedData: [],
        checkedElevators: [],
        filterText: "",
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
    },
    mounted() {
      this.getTree();
    },
    methods: {
      getTree() {
        this.loading = true;
        this.$api.getList("tree/elevator").then(res => {
          this.loading = false;
          this.data = res.data;
          console.log(res.data);
        }).catch(error => {
          this.loading = false;
          this.$message.error("获取树失败，" + error.response.data.message);
        });
      },
      filterNode(value, data, node) {
        if (!value) {
          return true;
        }
        if (node.parent.parent !== null && node.parent.visible) {
          return true;
        }
        return data.name.indexOf(value) !== -1;
      },
      check(nodes) {
        this.checkedData = [];
        this.checkedElevators = [];
        let useUnitKeys = [];
        for (let data of nodes) {
          if (data.type === "elevator") {
            this.checkedElevators.push(data);
            if (useUnitKeys.indexOf(data.parentKey) === -1) {
              useUnitKeys.push(data.parentKey);
              let useUnitNode = this.$refs.tree.getNode(data.parentKey);
              this.checkedData.push(useUnitNode.data);
            }
            this.checkedData.push(data);
          }
        }
      },
      deleteLine(line) {
        this.$refs.tree.setChecked(line.key, false);
        this.check(this.$refs.tree.getCheckedNodes());
      },
      deleteAll() {
        this.checkedData = [];
        this.checkedElevators = [];
      },
      submit() {
        this.$emit("submit", this.checkedElevators);
      },
    },
  };
</script>

<style lang="scss" scoped>
.vm-tree-multi-wrapper {
  width: 500px;
  display: flex;
  align-items: center;
  height: 100%;

  .vm-tree-wrapper {
    flex: 4;
    height: 100%;
    padding: 10px;
    border: 1px solid #d8dce5;
    background-color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: auto;

    .vm-tree-tree {
      margin-top: 8px;
    }

    ::v-deep .el-tree {
      background-color: inherit;
    }
  }

  .selected {
    flex: 3;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    height: 100%;
    padding: 10px;
    border: 1px solid #d8dce5;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .title {
      border-bottom: 1px solid #d8dce5;
      padding: 5px;
      text-align: center;
    }

    .lineContainer {
      margin-bottom: 40px;
      flex: 1;
      overflow: auto;

      .line {
        display: flex;
        align-items: center;
        justify-content: center;

        .useUnit {
          display: inline-block;
          background-color: #2d8cf0;
          color: white;
          font-size: 12px;
          border-radius: 4px;
          padding: 5px 10px;
          margin-top: 10px;
          margin-bottom: 5px;
        }

        .elevator {
          flex: 1;
          color: #666;
          text-align: left;
          padding: 3px;
          font-size: 14px;
        }

        .el-icon-circle-close {
          color: #CCC;
          cursor: pointer;
          float: right;

          &:hover {
            color: #777;
          }
        }
      }
    }

    .operation {
      width: 180px;
      position: absolute;
      margin-top: 10px;
      text-align: center;
      bottom: 10px;
    }
  }
}
</style>
